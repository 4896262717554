
import { Component, Vue } from 'vue-property-decorator'
import { Balance, BankTransfer, Buyer, GiftCard, MyBuyersBalanceMove } from "@/models";
import { clipboardSuccess } from '@/utils/clipboard'
import { useBalanceStore } from '@/stores/balance'

@Component({
  name: 'BuyersManagementView',
  components: {
    TopupBuyerDialog: () => import('../components/TopupBuyerDialog.vue'),
    EditBuyerFormCard: () => import('./EditBuyerFormCard.vue'),
    BalanceMovesTable: () => import('@/views/prepaid-account/BalanceMovesView/BalanceMovesTable.vue'),
    ExtractPrepaidAccountDialog: () => import('@/components/ExtractPrepaidAccountDialog/index.vue'),
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  balanceStore: any = useBalanceStore()

  private buyer: Buyer = new Buyer()
  private buyerLoading = true
  private clipboardSuccess = clipboardSuccess

  private topupDialogVisible = false
  private extractDialogVisible = false

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private reasonOptions = [
    {
      label: this.$t('kindPrepaidAccount.pull'),
      value: 'pull'
    },
    {
      label: this.$t('kindPrepaidAccount.overpayment'),
      value: 'overpayment'
    },
    {
      label: this.$t('kindPrepaidAccount.orderPayment'),
      value: 'order_payment'
    },
    {
      label: this.$t('kindPrepaidAccount.vat'),
      value: 'vat'
    },
    {
      label: this.$t('kindPrepaidAccount.topup'),
      value: 'topup'
    },
    {
      label: this.$t('kindPrepaidAccount.fix'),
      value: 'fix'
    },
    {
      label: this.$t('kindPrepaidAccount.pullRefund'),
      value: 'pull_refund'
    },
    {
      label: this.$t('kindPrepaidAccount.topupCancellation'),
      value: 'topup_cancellation'
    },
    {
      label: this.$t('kindPrepaidAccount.cardRefund'),
      value: 'card_refund'
    }
  ]

  get filters(): any {
    return this.balanceStore.filters
  }

  set filters(filters: any) {
    this.balanceStore.filters = filters
  }

  get lastTopup(): MyBuyersBalanceMove[] {
    return this.balanceStore.lastThreeTopup
  }

  async created() {
    await this.getBuyerById()
    await this.getGiftCards()

    this.balanceStore.filters.buyerId = this.buyer.id
    await this.balanceStore.fetchLastThreeTopupBuyer()
    await this.balanceStore.fetchBalanceMoves(true)
  }

  private async getBuyerById() {
    const { data } =
      await Buyer
        .includes(['balance', 'location'])
        .find(this.$route.params.id)

    this.buyer = data.dup()
    this.buyerLoading = false
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .stats({ total: 'count' })
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.filters.giftCardId = giftCardId
    this.refresh()
  }

  async handleCreateTopup(bankTransfer: BankTransfer): Promise<void> {
    if (this.buyer.balance) this.buyer.balance.amount += bankTransfer.amount
    else this.buyer.balance = new Balance({ amount: bankTransfer.amount })

    this.topupDialogVisible = false

    await this.balanceStore.fetchLastThreeTopupBuyer()
    await this.balanceStore.resetPagination()
    await this.balanceStore.fetchBalanceMoves(true)
  }

  async cancelTopup(topup: MyBuyersBalanceMove): Promise<void> {
    await topup.source.cancel()

    await this.getBuyerById()
    this.refresh()
  }

  truncateID(id: string): string {
    return `${id.slice(0, 4)} .... ${id.substr(id.length - 4)}`
  }

  refresh() {
    console.log('refresh')
    this.balanceStore.resetPagination()
    this.balanceStore.fetchBalanceMoves(true)
  }

  beforeDestroy() {
    this.balanceStore.resetFilters()
    this.balanceStore.resetPagination()
  }
}
